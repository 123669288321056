import React from 'react';
import { Link } from 'react-router-dom';
import power from '../../../images/home/ev.jpeg';
import powerSmall from '../../../images/home/evSmall.jpg';
import './style.css';

function Power() {
    return(
        <div className='power-wrapper'>
            {/* <img src={power} alt='' className='sm-screen-hide' />
            <img src={powerSmall} alt='' className='lg-screen-hide' /> */}
            <div className='centered-text container'>
                <h1>POWER YOUR LIFE</h1>
                {/* <span> */}
                <div className='row'>
                    <div className='col-12 col-sm-4 mb-2'>
                        <Link to="/residential">RESIDENTIAL</Link>
                    </div>
                    <div className='col-12 col-sm-4 mb-2'>
                        <Link to="/multifamily">MULTIFAMILY</Link>
                    </div>
                    <div className='col-12 col-sm-4 mb-2'>
                        <Link to="/commercial">COMMERCIAL</Link>
                    </div>
                </div>
                {/* </span> */}
            </div>
            <div className='overlay'></div>
        </div>
    )
}

export default Power;