import React from 'react';
import check from '../../../images/check.png';
import icon from '../../../images/home/icon03.png';
import icon2 from '../../../images/home/icon05.png';
import icon3 from '../../../images/home/icon02.png';
import icontrio from '../../../images/home/icontrio.png';
import './style.css';

function Benefits() {
    return(
        <div className='benefits-wrapper container-fluid'>
            <div className='row'>
                <div className='col-12 col-lg-6 my-auto text-center'>
                    {/* <img src={icon} alt='' />
                    <img src={icon2} alt='' />
                    <img src={icon3} alt='' /> */}
                    <img src={icontrio} alt='' />
                </div>
                <div className='col-12 col-lg-6 left-text'>
                    <h4 className='section-title'>YOU CAN COUNT ON TRANSBLUE</h4>
                    <p className='section-p'>
                        With nearly 20 years of experience as a leading provider of construction management services, you can count on Transblue for your next step into a greener future.
                        {/* We work with each client to achieve their specificgoals. We believe consistent communication creates transparency and synergy in ourpartnership. */}
                    </p>
                    <div className='list'>
                        <p className='section-p'><img src={check} alt='' className='check' /> 100% committed to customer satisfaction</p>
                        <p className='section-p'><img src={check} alt='' className='check' /> Consistently on time and on budget</p>
                        <p className='section-p'><img src={check} alt='' className='check' /> Great communication, management, and execution</p>
                        <p className='section-p'><img src={check} alt='' className='check' /> Professional, courteous, and friendly communications</p>
                        <p className='section-p'><img src={check} alt='' className='check' /> Each project is methodically designed with a World Class finish</p>
                        <p className='section-p'><img src={check} alt='' className='check' /> Comprehensive project scopes</p>
                        <p className='section-p'><img src={check} alt='' className='check' /> Quick bidding turnaround</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits;