import React, {useState} from 'react';
import ServiceModal from '../../serviceModal';
import solar from '../../../images/home/solar.jpg';
import ev from '../../../images/home/ev2.jpg';
//import roof from '../../../images/residential/roof.jpg';
import xeriscape from '../../../images/xeriscape.jpg'
import uparrow from '../../../images/arrows/uparrow50.png';
import downarrow from '../../../images/arrows/downarrow50.png';
import './style.css';

function Services() {
    const [displaySolar, setDisplaySolar] = useState(false);
    const [displayEv, setDisplayEv] = useState(false);
    const [displayGreen, setDisplayGreen] = useState(false);

    function toggle(section) {
        if(section === 'ev') setDisplayEv(!displayEv)
        else if(section === 'solar') setDisplaySolar(!displaySolar)
        else setDisplayGreen(!displayGreen)
    }

    return(
        <div className='home-services container-fluid'>
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <img src={solar} alt='solar panels' />
                    <p className='section-subheader'>SWITCH TO RENEWABLE ENERGY</p>
                    <hr />
                    {!displaySolar ? <p className='section-p'>Solar power systems derive clean, pure energy from the sun. Installing...</p>
                    : <p className='section-p'>Solar power systems derive clean, pure energy from the sun. Installing solar panels on your home or business helps combat greenhouse gas emissions and reduces our collective dependence on fossil fuel. Traditional electricity is sourced from fossil fuels such as coal and natural gas.</p>}
                    <button onClick={() => toggle('solar')}><img className='toggle' src={displaySolar ? uparrow : downarrow} alt='' /></button>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <img src={ev} alt='EV charger' />
                    <p className='section-subheader'>BUY ENERGY EFFICIENT</p>
                    <hr />
                    {!displayEv ? <p className='section-p'>LED bulbs consume less energy and emit less carbon dioxide than incandescent...</p>
                    : <p className='section-p'>LED bulbs consume less energy and emit less carbon dioxide than incandescent and CFL lightbulbs. Switching to LED bulbs reduces greenhouse emissions and carbon footprint.</p>}                    
                    <button onClick={() => toggle('ev')}><img className='toggle' src={displayEv ? uparrow : downarrow} alt='' /></button>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <img src={xeriscape} alt='Green Roof' />
                    <p className='section-subheader'>USE LESS WATER</p>
                    <hr />
                    {!displayGreen ? <p className='section-p'>It takes energy and resources to process and deliver water to our homes and businesses...</p>
                    : <p className='section-p'>It takes energy and resources to process and deliver water. By installing a xeriscape that requires very little water, you can reduce your carbon footprint without having to sacrifice a beautiful yard. </p>}                    
                    <button onClick={() => toggle('green')}><img className='toggle' src={displayGreen ? uparrow : downarrow} alt='' /></button>
                </div>
            </div>
            <div className='row'>
            <div className='service-modal'>
                    <ServiceModal />
                </div>
            </div>

        </div>
    )
}

export default Services;