import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Header from '../components/header';
import ContactCta from '../components/contactCta';
import About from '../components/home/about';
import Carbon from '../components/home/carbon';
import Benefits from '../components/home/benefits';
import Services from '../components/home/services';
import FullServices from '../components/home/fullServices';
import Power from '../components/home/power';
import Footer from '../components/footer';
import header from '../images/home/header.jpg';

import ReactGA from 'react-ga';
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Home() {
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'Visited EV Landing Page'
          });
    }, [])

    return(
        <Fragment>
            <Helmet>
                <title>Transblue Green Solutions</title>
                <meta name='description' content=''></meta>
            </Helmet>
            <Header img={header}
            title='GREEN SOLUTIONS'
            name='home' />
            <ContactCta />
            <Navbar page='HOME' parentPage='ev' />
            <div className='wrapper'>
            <About />
            <Carbon />
            <Services />
            <Benefits />
            {/* <FullServices /> */}
            </div>
            <Power />
            <Footer />
        </Fragment>
    )
}

export default Home;