import React, {Fragment} from 'react';
import './style.css';

function ServiceModal() {

    return(
        <Fragment>
            <button className='cta' data-toggle="modal" data-target="#serviceModal">
                VIEW ALL SERVICES
            </button>
            
            <div className='service-modal-wrapper'>
                <div className="modal fade" id="serviceModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {/* <img src={vines} alt='' /> */}
                            <div className='list'>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className='section-title'>THINK GREEN</h4>
                                <p>GREEN ROOFS</p>
                                <p>PERMEABLE PAVEMENT</p>
                                <p>BIOSWALES</p>
                                <p>SOLAR PANELS</p>
                                <p>EV CHARGERS</p>
                                <p>XERISCAPES</p>
                            </div>
                        </div>      
                    </div>
                </div>
                </div>
        </Fragment>
    )
}

export default ServiceModal;