import React from 'react';
import earth from '../../../images/home/earth.jpeg';
import './style.css';

function Carbon() {
    return(
        <div className='carbon-wrapper container-fluid'>
            <div className='row'>
                <div className='col-12 col-lg-6'>
                    <img src={earth} alt='' />
                </div>
                
                <div className='col-12 col-lg-5 my-auto left-text'>
                    {/* <h6>THINK GREEN</h6> */}
                    {/* <h4 className='section-title'>THINK GREEN</h4> */}
                    <small className='section-subheader'>TOGETHER WE CAN</small>
                    <h4 className='section-title'>MAKE THE WORLD A BETTER PLACE</h4>
                    <p className='section-p'>
                        Average global temperatures are increasing, extreme weather events are becoming more severe, ocean levels are rising, and acidification is occurring. It's important that we all do our part.
                        {/* With global warming on the rise, it's important that we all do our part in combatting it. */}
                    </p>
                    <p className='section-p'>At Transblue, we are here to help. From solar panels that renew energy to EV Chargers that cut down on carbon emissions, we have it all.</p>
                </div>
                <div className='col-lg-1'></div>
            </div>
        </div>
    )
}

export default Carbon;