import React from 'react';
import './style.css';

function About() {
    return(
        <div className='home-about-wrapper container-fluid'>
            <div className='row'>
                <div className='col-sm-12 col-md-6'>
                    <h6>GREEN SOLUTIONS</h6>
                    <h4>FOR THE WORLD OF TOMORROW</h4>
                </div>
                <div className='col-sm-12 col-md-6 my-auto'>
                    <p className='section-p'>
                    Transblue understands the growing need for green solutions. With the growing amount of electric cars and the focus on green living, Transblue is dedicated to being a part of the solution. From building more charging stations to cut down on range anxiety to the education of the importance of green solutions, Transblue paves the way to tomorrow.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About;