import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './hero/home';
import './App.css';

const Commercial = lazy(() => import('./hero/commercial'));
const Multifamily = lazy(() => import('./hero/multifamily'));
const Contact = lazy(() => import('./hero/contact/index'));
const Residential = lazy(() => import('./hero/residential'));

function App() {
  return (
    // <HelmetProvider>
    //   <Router>
    //     <Suspense fallback={<div>loading...</div>}>
    //       <Route exact path='/evindex/' component={Home}></Route>
    //       <Route exact path='/evindex/commercial' component={Commercial}></Route>
    //       <Route exact path='/evindex/multifamily' component={Multifamily}></Route>
    //       <Route exact path='/evindex/contact' component={Contact}></Route>
    //       <Route exact path='/evindex/residential' component={Residential}></Route>
    //     </Suspense>
    //   </Router>
    // </HelmetProvider>

    <HelmetProvider>
    <Router>
      <Suspense fallback={<div>loading...</div>}>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/commercial' component={Commercial}></Route>
        <Route exact path='/multifamily' component={Multifamily}></Route>
        <Route exact path='/contact' component={Contact}></Route>
        <Route exact path='/residential' component={Residential}></Route>
      </Suspense>
    </Router>
  </HelmetProvider>
  );
}

export default App;
